import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: '8bslassu',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skg4OyrrD6XpXYtgf3gvRPOmZdeE1EnemH3eswCqkzwi2W1uYvJPkcS6NZXiLPpluaB0hrTndSZNpwQeZgjgbwcthE3oVI0dJpqhLIotJbL6Jicffl6XVSaQqJf6WRx9hlR0ItaxwvzcygntegFhqB1dhmGmCgUCuTn2IYAQMm2Bh97cxjrW',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
